/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { OnInit } from '@angular/core';
import { ApiHostService } from "../service/api-host.service";
import { ApiVersionService } from "../service/api-version.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
var AppComponent = /** @class */ (function () {
    function AppComponent(apiHostService, apiVersionService, router) {
        this.apiHostService = apiHostService;
        this.apiVersionService = apiVersionService;
        this.router = router;
        this._apiUrl = apiHostService.chosenApiUrl;
        this._apiUsername = apiHostService.chosenApiUsername;
        this._apiPassword = apiHostService.chosenApiPassword;
        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }
    AppComponent.prototype.ngOnInit = function () {
        this.loadVersions();
    };
    AppComponent.prototype.loadVersions = function () {
        var _this = this;
        this.apiVersion = null;
        this.apiProtocolVersion = null;
        this.clientProtocolVersion = environment.protocolVersion;
        this.apiVersionService.getVersion().subscribe(function (version) {
            _this.apiVersion = version.version;
            _this.apiVersionService.getProtocolVersion().subscribe(function (version) {
                _this.apiProtocolVersion = version.version;
            });
        });
    };
    AppComponent.prototype.canConnect = function () {
        return this.apiVersion != null && this.apiProtocolVersion == this.clientProtocolVersion;
    };
    Object.defineProperty(AppComponent.prototype, "apiUrl", {
        get: function () {
            return this._apiUrl;
        },
        set: function (apiUrl) {
            if (!apiUrl.startsWith('http')) {
                apiUrl = 'http://' + apiUrl;
            }
            this.apiHostService.setChosenApiUrl(apiUrl);
            this._apiUrl = apiUrl;
            this.loadVersions();
            this.router.navigate([this.router.url.split('?')[0]]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "apiUsername", {
        get: function () {
            return this._apiUsername;
        },
        set: function (apiUsername) {
            this.apiHostService.setChosenApiUsername(apiUsername);
            this._apiUsername = apiUsername;
            this.loadVersions();
            this.router.navigate([this.router.url.split('?')[0]]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "apiPassword", {
        get: function () {
            return this._apiPassword;
        },
        set: function (apiPassword) {
            this.apiHostService.setChosenApiPassword(apiPassword);
            this._apiPassword = apiPassword;
            this.loadVersions();
            this.router.navigate([this.router.url.split('?')[0]]);
        },
        enumerable: true,
        configurable: true
    });
    return AppComponent;
}());
export { AppComponent };
