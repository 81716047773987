/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/internal/operators/catchError";
import {MatSnackBar} from "@angular/material";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(public snackBar: MatSnackBar) {

	}

	intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
		return handler.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				this.snackBar.open(error.message, "", {
					duration: 5000,
				});
				return throwError(error);
			})
		);
	}
}
