/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [".footer[_ngcontent-%COMP%]{bottom:0;width:100%;height:40px;color:#fff;text-align:right;font-size:12px;margin:0}.middle[_ngcontent-%COMP%]{width:100%;height:90%}"];
export { styles as styles };
