/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './component/app.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatIconModule, MatInputModule, MatMenuModule, MatSnackBarModule, MatToolbarModule} from '@angular/material';
import {AppRoutingModule} from './app-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AuthInterceptor} from "./service/interceptor/auth.interceptor";
import {ApiHostService} from "./service/api-host.service";
import {ApiVersionService} from "./service/api-version.service"
import {ErrorInterceptor} from "./service/interceptor/error.interceptor";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatToolbarModule,
		MatButtonModule,
		MatMenuModule,
		MatInputModule,
		MatIconModule,
		FlexLayoutModule,
		AppRoutingModule,
		MatSnackBarModule
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		ApiHostService,
		ApiVersionService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
