/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */


import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable()
export class ApiHostService {

	chosenApiUrl: string;
	chosenApiUsername: string;
	chosenApiPassword: string;

	constructor() {
		this.setChosenApiUrl(localStorage.getItem("apiChosenApiUrl"));
		this.setChosenApiUsername(localStorage.getItem("apiChosenApiUsername"));
		this.setChosenApiPassword(localStorage.getItem("apiChosenApiPassword"));
	}

	setChosenApiUrl(apiUrl: string) {
		this.storeChosenApiUrl(apiUrl != null ? apiUrl : environment.defaultApiUrl)
	}

	setChosenApiUsername(apiUsername: string) {
		this.storeChosenApiUsername(apiUsername != null ? apiUsername : environment.defaultApiUsername)
	}

	setChosenApiPassword(apiPassword: string) {
		this.storeChosenApiPassword(apiPassword != null ? apiPassword : environment.defaultApiPassword)
	}

	private storeChosenApiUrl(apiUrl: string) {
		this.chosenApiUrl = apiUrl;
		localStorage.setItem("apiChosenApiUrl", this.chosenApiUrl);
	}

	private storeChosenApiUsername(apiUsername: string) {
		this.chosenApiUsername = apiUsername;
		localStorage.setItem("apiChosenApiUsername", this.chosenApiUsername);
	}

	private storeChosenApiPassword(apiPassword: string) {
		this.chosenApiPassword = apiPassword;
		localStorage.setItem("apiChosenApiPassword", this.chosenApiPassword);
	}

	getApiUrl() {
		return this.chosenApiUrl;
	}

	getApiUsername() {
		return this.chosenApiUsername;
	}

	getApiPassword() {
		return this.chosenApiPassword;
	}
}
