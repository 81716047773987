/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ApiVersionService {

	constructor(
		private http: HttpClient,
	) { }

	getVersion(): Observable<Version> {
		return this.http.get<Version>(`/version`);
	}

	getProtocolVersion(): Observable<Version> {
		return this.http.get<Version>(`/protocolVersion`);
	}
}

export class Version {
	version: string;
}
