/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiHostService} from "../api-host.service";

/**
 * @author Pere
 * @since 2017/07/30
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private apiHostService: ApiHostService
	) {
	}

	intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
		const url = request.url;

		// Ignore resources
		if (!url.includes('/assets/')) {
			request = request.clone({
				url: this.apiHostService.getApiUrl() + url
			});

			const auth = window.btoa(`${this.apiHostService.getApiUsername()}:${this.apiHostService.getApiPassword()}`);
			request = request.clone({
				setHeaders: {
					'Authorization': `Basic ${auth}`
				}
			});
		}

		return handler.handle(request);
	}
}

