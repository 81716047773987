/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {Component, OnInit} from '@angular/core';
import {ApiHostService} from "../service/api-host.service";
import {ApiVersionService} from "../service/api-version.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	_apiUrl: string;
	_apiUsername: string;
	_apiPassword: string;

	apiVersion: string;
	apiProtocolVersion: string;

	clientProtocolVersion: string;

	constructor(
		private apiHostService: ApiHostService,
		private apiVersionService: ApiVersionService,
		private router: Router
	) {
		this._apiUrl = apiHostService.chosenApiUrl;
		this._apiUsername = apiHostService.chosenApiUsername;
		this._apiPassword = apiHostService.chosenApiPassword;

		// override the route reuse strategy
		this.router.routeReuseStrategy.shouldReuseRoute = function() {
			return false;
		};
	}

	ngOnInit(): void {
		this.loadVersions();
	}

	loadVersions(): void {
		this.apiVersion = null;
		this.apiProtocolVersion = null;
		this.clientProtocolVersion = environment.protocolVersion;

		this.apiVersionService.getVersion().subscribe(version => {
			this.apiVersion = version.version;

			this.apiVersionService.getProtocolVersion().subscribe(version => {
				this.apiProtocolVersion = version.version;
			});
		});
	}

	canConnect(): boolean {
		return this.apiVersion != null && this.apiProtocolVersion == this.clientProtocolVersion;
	}

	get apiUrl() {
		return this._apiUrl;
	}
	get apiUsername() {
		return this._apiUsername;
	}
	get apiPassword() {
		return this._apiPassword;
	}

	set apiUrl(apiUrl: string) {
		if (!apiUrl.startsWith('http')) {
			apiUrl = 'http://' + apiUrl
		}
		this.apiHostService.setChosenApiUrl(apiUrl);
		this._apiUrl = apiUrl;

		this.loadVersions();
		this.router.navigate([this.router.url.split('?')[0]])
	}

	set apiUsername(apiUsername: string) {
		this.apiHostService.setChosenApiUsername(apiUsername);
		this._apiUsername = apiUsername;

		this.loadVersions();
		this.router.navigate([this.router.url.split('?')[0]])
	}

	set apiPassword(apiPassword: string) {
		this.apiHostService.setChosenApiPassword(apiPassword);
		this._apiPassword = apiPassword;

		this.loadVersions();
		this.router.navigate([this.router.url.split('?')[0]])
	}
}
