<!--
  ~ Copyright (C) [2013] - [2019] Operartis llc
  ~ All Rights Reserved.
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  -->

<mat-toolbar color="primary">
	<div>Matchimus</div>
	<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Main menu">
		<mat-icon>more_vert</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item [routerLink]="['/processes']">
			<span>Processes</span>
		</button>
		<button mat-menu-item [routerLink]="['/domains']">
			<span>Domains</span>
		</button>
	</mat-menu>
	<!-- This fills the remaining space of the current row -->
	<div [style.flex]="'1 1 auto'"></div>
	<div fxLayout="row" style="font-size: medium; margin-top: 20px">
		<mat-form-field [style.width]="'420px'">
			<input matInput placeholder="API Url" [(ngModel)]="apiUrl">
		</mat-form-field>
		<mat-form-field [style.width]="'150px'">
			<input matInput placeholder="API Username" [(ngModel)]="apiUsername">
		</mat-form-field>
		<mat-form-field [style.width]="'150px'">
			<input matInput type="password" placeholder="API Password" [(ngModel)]="apiPassword">
		</mat-form-field>
	</div>
</mat-toolbar>
<ng-container *ngIf="canConnect()">
	<router-outlet></router-outlet>
	<mat-toolbar color="primary" class="footer" fxLayout="row" fxLayoutAlign="end center">
		Matchimus Version: {{apiVersion}}
	</mat-toolbar>
</ng-container>
<div class="middle" fxLayout="column" fxLayoutAlign="center center" *ngIf="!canConnect()">
	<p class="mat-display-1">Unable to connect to Matchimus</p>
	<p *ngIf="!apiVersion" class="mat-title">Please review your API connection parameters above.</p>
	<p *ngIf="apiVersion" class="mat-title">Wrong protocol version. Client: {{clientProtocolVersion}}; Server: {{apiProtocolVersion}}</p>
</div>
