/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const environment = {
	production: false,
	protocolVersion: "414",
	defaultApiUrl: 'https://matchimus-api-acceptance.operartis.com',
	defaultApiUsername: 'matchimus',
	defaultApiPassword: 'makethemost'
};
