/*
 * Copyright (C) [2013] - [2019] Operartis llc
 * All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';

/**
 * @author Pere
 * @since 30/07/2017
 */
const routes: Routes = [
	{path: '', redirectTo: 'processes', pathMatch: 'full'},
	{path: 'domains', loadChildren: './component/domain/domains.module#DomainsModule'},
	{path: 'processes', loadChildren: './component/process/processes.module#ProcessesModule'},
	{path: 'diagnostics', loadChildren: './component/diagnostics/diagnostics.module#DiagnosticsModule'},
	{path: 'processes/:processId/assessment-report', loadChildren: './component/process/match-report/match-report.module#MatchReportModule'},
	{path: 'processes/:processId/match-differences', loadChildren: './component/process/match-differences/match-differences.module#MatchDifferencesModule'},
	{path: 'processes/:processId/training-report', loadChildren: './component/process/training-report/training-report.module#TrainingReportModule'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
